<!--
 * @Author: filename
 * @Description: 家庭成员信息
-->
<template>
    <el-form
        ref="userForm"
        v-loading="isLoading"
        :model="userForm"
        label-width="auto"
        label-position="left"
    >
        <!-- 配偶  -->
        <div
            v-if="userForm.information.married === '1' || userForm.information.married === '4'"
            class="card-container"
            style="margin-bottom: 20px;"
        >
            <div class="card-heard">主申请人配偶</div>
            <template v-for="(item, idx) in userForm.subsetList">
                <div v-if="item.relation === '3'" :key="idx" class="form-box">
                    <el-row :gutter="20">
                        <el-col :span="24">
                            <el-form-item
                                label="是否随行："
                                :prop="'subsetList.' + idx + '.follow'"
                                :rules="[{required: true, message: '请选择是否随行', trigger: 'change'}]"
                            >
                                <el-radio-group v-model="item.follow">
                                    <el-radio :label="1">随行</el-radio>
                                    <el-radio :label="2">不随行</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="配偶姓名："
                                :prop="'subsetList.' + idx + '.subsetName'"
                                :rules="[{required: item.follow === 1 ? true : false, message: '请输入配偶姓名', trigger: 'blur'}]"
                            >
                                <el-input v-model.trim="item.subsetName" clearable maxlength="20" placeholder="请输入" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="性别："
                                :prop="'subsetList.' + idx + '.sex'"
                                :rules="[{required: item.follow === 1 ? true : false, message: '请选择性别', trigger: 'change'}]"
                            >
                                <el-select
                                    v-model="item.sex"
                                    placeholder="请选择性别"
                                    style="width: 100%;"
                                    clearable
                                >
                                    <el-option label="男" :value="1" />
                                    <el-option label="女" :value="2" />
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="出生日期："
                                :prop="'subsetList.' + idx + '.birthday'"
                                :rules="[{required: item.follow === 1 ? true : false, message: '请选择出生日期', trigger: 'change'}]"
                            >
                                <el-date-picker
                                    v-model="item.birthday"
                                    style="width: 100%;"
                                    value-format="yyyy-MM-dd"
                                    type="date"
                                    :picker-options="pickerBeginDateBefore"
                                    placeholder="请选择"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="档案号："
                                :prop="'subsetList.' + idx + '.fileNumber'"
                            >
                                <div style="display:flex;">
                                    <FileNumberInput v-model="item.fileNumber" />
                                </div>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="港澳通行证号码：">
                                <el-input v-model.trim="item.passNumber" clearable placeholder="请输入" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="护照号码：">
                                <el-input v-model.trim="item.identityCard" clearable placeholder="请输入" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="国籍："
                                :prop="'subsetList.' + idx + '.nationality'"
                                :rules="[{required: item.follow === 1 ? true : false, message: '请输入匹配选择国籍', trigger: 'change'}]"
                            >
                                <CountrySelect v-model="item.nationality" style="width: 100%;" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12"/>
                        <el-col :span="24">
                            <el-form-item label="现居地地址：">
                                <el-radio-group v-model="item.nows">
                                    <el-radio :label="1">与主申请人相同</el-radio>
                                    <el-radio :label="0">与主申请人不相同</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                        <el-col v-if="item.nows === 0" :span="24">
                            <el-form-item label="现居地地址：">
                                <CurrentAddress ref="CurrentAddress" v-model="item.address" country-placeholder="请输入匹配国家" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
            </template>
        </div>

        <!-- 子女 -->
        <div class="card-container">
            <div class="card-heard">子女</div>
            <template v-for="(item, idx) in userForm.subsetList">
                <div v-if="item.relation === '4'" :key="idx" :class="['form-box', idx > 1 ? 'card-line' : '' ]">
                    <div class="del-btn">
                        <el-button type="text" @click="removeMember(idx)">删除</el-button>
                    </div>
                    <el-row :gutter="20">
                        <el-col :span="24">
                            <el-form-item
                                label="是否随行："
                                :prop="'subsetList.' + idx + '.follow'"
                                :rules="[{required: true, message: '请选择是否随行', trigger: 'change'}]"
                            >
                                <el-radio-group v-model="item.follow">
                                    <el-radio :label="1">是</el-radio>
                                    <el-radio :label="2">否</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="子女姓名："
                                :prop="'subsetList.' + idx + '.subsetName'"
                                :rules="[{required: item.follow === 1 ? true : false, message: '请输入配偶姓名', trigger: 'blur'}]"
                            >
                                <el-input v-model.trim="item.subsetName" clearable maxlength="20" placeholder="请输入" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="性别："
                                :prop="'subsetList.' + idx + '.sex'"
                                :rules="[{required: item.follow === 1 ? true : false, message: '请选择性别', trigger: 'change'}]"
                            >
                                <el-select
                                    v-model="item.sex"
                                    placeholder="请选择性别"
                                    style="width: 100%;"
                                    clearable
                                >
                                    <el-option label="男" :value="1" />
                                    <el-option label="女" :value="2" />
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="出生日期："
                                :prop="'subsetList.' + idx + '.birthday'"
                                :rules="[{required: item.follow === 1 ? true : false, message: '请选择出生日期', trigger: 'change'}]"
                            >
                                <el-date-picker
                                    v-model="item.birthday"
                                    style="width: 100%;"
                                    value-format="yyyy-MM-dd"
                                    type="date"
                                    :picker-options="pickerBeginDateBefore"
                                    placeholder="请选择"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="档案号："
                                :prop="'subsetList.' + idx + '.fileNumber'"
                            >
                                <div style="display:flex;">
                                    <FileNumberInput v-model="item.fileNumber" />
                                </div>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="港澳通行证号码：">
                                <el-input v-model.trim="item.passNumber" clearable placeholder="请输入" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="护照号码：">
                                <el-input v-model.trim="item.identityCard" clearable placeholder="请输入" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="国籍："
                                :prop="'subsetList.' + idx + '.nationality'"
                                :rules="[{required: item.follow === 1 ? true : false, message: '请输入匹配选择国籍', trigger: 'change'}]"
                            >
                                <CountrySelect v-model="item.nationality" style="width: 100%;" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12"/>
                        <el-col :span="24">
                            <el-form-item label="现居地地址：">
                                <el-radio-group v-model="item.nows">
                                    <el-radio :label="1">与主申请人相同</el-radio>
                                    <el-radio :label="0">与主申请人不相同</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                        <el-col v-if="item.nows == 0" :span="24">
                            <el-form-item label="现居地地址：">
                                <CurrentAddress ref="CurrentAddress" v-model="item.address" country-placeholder="请输入匹配国家" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
            </template>
            <div class="add-people" @click="addMember">+新增成员</div>
        </div>
        <!-- 保存按钮 -->
        <div class="operations-group">
            <el-button class="person-btn" @click="handleLastStep">上一步</el-button>
            <el-button v-if="(user.baseSchedule - 1) < 2" class="person-btn btn-blue" :loading="isLoading" @click="handleSave">保存</el-button>
            <el-button class="person-btn btn-blue" :loading="isLoading" @click="handleNextSave">下一步</el-button>
        </div>
    </el-form>
</template>

<script lang="javascript">
import CountrySelect from "@/components/CountrySelect/CountrySelect"
import FileNumberInput from "@/components/FileNumberInput/FileNumberInput"
import CurrentAddress from "@/components/CurrentAddress/CurrentAddress"
import CitySelect from "@/components/CitySelect/CitySelect";
import { getInformationDetail, smallSubsetSave, smallSubsetSubmit } from "@/api/workfow.js";
import { mapActions, mapState } from "vuex";
export default {
    name: "SmallStep2",
    components: {
        CountrySelect,
        FileNumberInput,
        CurrentAddress,
    },
    data() {
        return {
            // 只能选择当天之前的日期
            pickerBeginDateBefore: {
                disabledDate(time) {
                    return time.getTime() >= Date.now() - 8.64e6;
                },
            },
            checked: true,
            userForm: {
                subsetList: [
                    {
                        orderId: this.$route.params.id,
                        subsetName: '', // 名称
                        sex: '', // 性别: 0为未知, 1为男, 2为女
                        relation: '', // 关系:1：主申父亲, 2：主申母亲, 3: 配偶 , 4：子女, 5：兄弟姐妹
                        nows: 0, //现居住地址 0: 不一样 1: 一样
                        passNumber: '', // 港澳通行证
                        birthday: '', // 出生日期
                        fileNumber: '', // 档案号
                        identityCard: '', // 护照
                        follow: '', // 是否随同 0：待定 1：跟随 2：不跟随
                        address: {
                            //目前居住地址
                            area: [], //中国的话选城市
                            foreign: "", //外国的话输入城市
                            details: "", //目前居住地址与主申请人不同的详细地址
                            country: "", //目前居住国家国外的话 输入框国外的国家
                        },
                        nationality: '' // 国籍
                    },
                ],
                order: {},
                information: {
                    married: '',
                }
            },
            marriedStatus: '1', // 婚姻状态 1=已婚,2=未婚,3=离婚,4=分居 5-丧偶
            isLoading: false,
            isSave: false, //防抖loading
            isNext: false,
        };
    },
    computed: {
        ...mapState(["user"])
    },
    created() {
        this.getDetail()
    },
    methods: {
        // 获取详情
        async getDetail() {
            try {
                this.isLoading = true
                const { code, data } = await getInformationDetail({ orderId: this.$route.params.id })
                if (code === 200) {
                    this.userForm.subsetList = data.subsetList
                    this.userForm.information = data.information
                    this.userForm.order = data.order
                    if (data.information.married === '1' || this.userForm.information.married === '4') {
                        const spouse = data.subsetList.filter(item => item.relation === '3')
                        if (spouse.length === 0) {
                            this.userForm.subsetList.push({
                                orderId: this.$route.params.id,
                                subsetName: '', // 名称
                                sex: '', // 性别: 0为未知, 1为男, 2为女
                                relation: '3', // 关系:1：主申父亲, 2：主申母亲, 3: 配偶 , 4：子女, 5：兄弟姐妹
                                nows: '', //现居住地址 0: 不一样 1: 一样
                                passNumber: '', // 港澳通行证
                                birthday: '', // 出生日期
                                fileNumber: '', // 档案号
                                identityCard: '', // 护照
                                follow: '', // 是否随同 0：待定 1：跟随 2：不跟随
                                address: {
                                    //目前居住地址
                                    area: [], //中国的话选城市
                                    foreign: "", //外国的话输入城市
                                    details: "", //目前居住地址与主申请人不同的详细地址
                                    country: "", //目前居住国家国外的话 输入框国外的国家
                                },
                                nationality: '' // 国籍
                            })
                        }
                    }
                }
            } catch (error) {
                console.log(error)
            } finally {
                this.isLoading = false
            }
        },
        // 增加子女
        addMember() {
            this.userForm.subsetList.push(
                {
                    orderId: this.$route.params.id,
                    subsetName: '', // 名称
                    sex: '', // 性别: 0为未知, 1为男, 2为女
                    relation: '4', // 关系:1：主申父亲, 2：主申母亲, 3: 配偶 , 4：子女, 5：兄弟姐妹
                    nows: '', //现居住地址 0: 不一样 1: 一样
                    passNumber: '', // 港澳通行证
                    birthday: '', // 出生日期
                    fileNumber: null, // 档案号
                    identityCard: '', // 护照
                    follow: '', // 是否随同 0：待定 1：跟随 2：不跟随
                    address: {
                        //目前居住地址
                        area: [], //中国的话选城市
                        foreign: "", //外国的话输入城市
                        details: "", //目前居住地址与主申请人不同的详细地址
                        country: "", //目前居住国家国外的话 输入框国外的国家
                    },
                    nationality: '' // 国籍
                },
            )
        },
        // 移除子女
        removeMember(index) {
            this.$confirm("确定要删除该条子女信息吗？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                this.userForm.subsetList.splice(index, 1)
            }).catch(() => {})
        },
        // 上一步
        handleLastStep() {
            this.$router.replace({
                name: "SmallStep1",
                query: {
                    id: this.$route.query.id
                }
            })
        },
        // 保存
        async handleSave() {
            try {
                localStorage.setItem("stepChange", 0)
                this.$store.commit("stepChange")
                this.isLoading = true
                const { code } = await smallSubsetSave({ subsetList: this.userForm.subsetList })
                if (code === 200) {
                    this.$message.success("保存成功！")
                }

            } catch (error) {
                console.log(error)
            } finally {
                this.isLoading = false
            }
        },
        // 下一步
        handleNextSave() {
            this.$refs.userForm.validate(async (valid) => {
                if (valid) {
                    try {
                        localStorage.setItem("stepChange", 0)
                        this.$store.commit("stepChange")
                        this.isLoading = true
                        const { code } = await smallSubsetSubmit({ subsetList: this.userForm.subsetList })
                        if (code === 200) {
                            this.$message.success("提交成功！")
                            this.$router.replace({
                                name: "SmallStep3",
                                query: {
                                    id: this.$route.query.id
                                }
                            })
                        }
                    } catch (error) {
                        console.log(error)
                    } finally {
                        this.isLoading = false
                    }
                }
            })
        },
    },
};
</script>

<style lang="less" scoped>
.card-container {
    width: 960px;
    margin: 0 auto;
    .card-heard {
        height: 35px;
        line-height: 35px;
        background: #409eff;
        padding-left: 16px;
        border-radius: 4px 4px 0px 0px;
        color: #fff;
    }
}

.operations-group {
    width: 960px;
    margin: 25px auto 50px;
    display: flex;
    justify-content: flex-end;
}

.form-box {
    padding: 20px;
}
.card-line {
    border-top: 1px solid #eee;
    padding-top: 35px;
}
.add-people {
    border: 1px solid #dcdfe6;
    color: #409eff;
    font-size: 14px;
    text-align: center;
    margin: 16px;
    cursor: pointer;
    padding: 10px 0;
}
.del-btn {
    font-size: 16px;
    text-align: right;
}
</style>
